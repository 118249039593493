<template>
  <div class="fill-height backdrop">
    <v-system-bar app color="secondary" dark fixed height="84" class="pa-0">
      <div style="height: 60px; width: 100%" class="d-flex pr-5 pl-3">
        <v-btn icon v-if="$vuetify.breakpoint.mobile" @click.stop="isMenuOpen = !isMenuOpen"><v-icon>$menu</v-icon></v-btn>
        <VSpacer/>
        <AppBarMenu/>
      </div>
      <div style="background-color: primary" class="repeater">
      </div>
    </v-system-bar>
    <v-main class="fill-height">
      <v-navigation-drawer v-model="isMenuOpen" app clipped width="316" color="pt-6">
        <TheMainMenu class="pl-4"/>
      </v-navigation-drawer>
      <router-view/>
    </v-main>
  </div>
</template>

<script>
import AppBarMenu from '@/components/menu/AppBarMenu.vue';
import TheMainMenu from '@/components/menu/TheMainMenu.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'Default',
  components: {
    AppBarMenu,
    TheMainMenu,
  },
  data() {
    return {
      cssProps: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundImage: `url('@/images/repeater.png')})`
      },
      isMenuOpen: true,
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters('authorisation', ['isLoggedIn']),
  },
  async created() {
    if (!this.isLoggedIn) this.$router.push({ name: 'login' });
  },
  methods: {
  },
};
</script>
