<template>
  <v-list tile nav class="pr-0">
    <template v-for="item in items">
      <v-list-group v-if="!!item.items && item.items.length"
                    active-class="activeClass"
                    v-model="item.active"
                    :disabled="item.disabled"
                    :prepend-icon="item.icon"
                    :key="item.title">
        <template #activator>
          <KListTitle v-bind="item"/>
        </template>
        <KListTitle v-bind="subItem" v-for="subItem in item.items" :key="subItem.title"/>
      </v-list-group>

      <KListTitle v-else :key="item.title" v-bind="item"/>
    </template>
  </v-list>
</template>

<script>
import KListTitle from '@/components/menu/KListTitle.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'TheMainMenu',
  components: { KListTitle },
  computed: {
    ...mapGetters({
    }),
    items() {
      return [
        {
          route: { name: 'home' },
          title: this.$t('home.menuTitle'),
          icon: '$home',
          sort: 0,
        },
        {
          route: { name: 'puzzle.index' },
          title: this.$t('puzzle.menuTitle'),
          icon: '$puzzlePiece',
          sort: 1,
        },
      ];
    },
  },
};
</script>

<style scoped>
.activeClass {
  color:            #ffffff;
  background-color: #000000;
  border-radius:    0;
}
</style>
